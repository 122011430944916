import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "select", "step", "document", "accordion"];

  onchange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    const documentType = event.target.dataset.documentType;
    const selectTargets = this.selectTargets.filter(
      (e) => e.dataset.documentType === documentType
    );
    const documentTarget = this.documentTargets.filter(
      (e) => e.dataset.documentType === documentType
    )[0];
    const accordionTargets = this.accordionTargets.filter(
      (e) => e.dataset.documentType === documentType
    );

    accordionTargets.forEach((accordionTarget) => {
      if (accordionTarget.classList.contains("visually-hidden"))
        accordionTarget.classList.remove("visually-hidden");
    });

    if (documentTarget.classList.contains("visually-hidden"))
      documentTarget.classList.remove("visually-hidden");

    documentTarget.querySelector("#workflow__filename").innerHTML = file.name;
    documentTarget.querySelector("#workflow__size").innerHTML =
      this.formatBytes(file.size);
    documentTarget.querySelector("#workflow__date").innerHTML =
      file?.lastModifiedDate?.toLocaleDateString("en-GB") ||
      new Date().toLocaleDateString("en-GB");

    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      const data = evt.target.result;
      const byteLength = data.byteLength;
      const ui8a = new Uint8Array(data, 0);

      var headerString = "";
      for (var i = 0; i < byteLength; i++) {
        const char = String.fromCharCode(ui8a[i]);
        if (char.match(/[^\r\n]+/g) !== null) {
          headerString += char;
        } else {
          break;
        }
      }
      headerString = headerString
        .replace(/^\uFEFF/, "")
        .replace(/^\u00EF\u00BB\u00BF/, "");
      const headers = headerString.split(",").filter((n) => n);
      selectTargets.forEach((target) => {
        target.options.length = 1;
        headers.forEach((header) => {
          const newOption = new Option(header, header);
          target.appendChild(newOption);
        });
      });
    };
  }

  changeStep(event) {
    const parentId = event.target.dataset.currentTarget;
    const targetId = event.target.dataset.nextTarget;

    document.getElementById(parentId).classList.toggle("visually-hidden");
    document.getElementById(targetId).classList.toggle("visually-hidden");
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
